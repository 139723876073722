<template>
    <div id="contenedor">
        <div id="borde">
            <div id="header">
                <div class="header_icons">
                    <router-link to="/pruebate"><img @click="soundButton.play()" src="../assets/icono_atras.svg" alt="Vidas" class="icono"></router-link>
                </div>
                <div class="header_icons">
                    <img @click="soundButton.play()" src="../assets/icono_speaker.svg" alt="Sonido" class="icono">
                    <router-link to="/"><img @click="soundButton.play()" src="../assets/icono_home.svg" alt="Inicio" class="icono"></router-link>
                </div>
            </div>

            <div id="supercontainer">
                <div id="title">Tu puntaje es:</div>
                <div id="puntaje">{{ puntaje }}</div>
                <div id="main_container">
                    <img src="../assets/logo_personajes_lg.svg" id="logo_submit">
                    <div id="formulario">
                        <div id="form_text">Envía tu nombre para entrar en el ranking:</div>
                        <div id="form_input_container">
                            <input id="form_text_input" type="text" v-model="playername" maxlength="15">
                            <div id="form_button" @click="soundButton.play(); submit_score()"><img src="../assets/icono_send.svg" id="form_send"></div>
                        </div>
                    </div>
                </div>
                <div id="footer">
                    <div class="combo_footer" style="display:none;">
                        <img src="../assets/icono_share.svg" alt="Compartir" class="icono_footer">
                        <div class="texto_footer">Compartir</div>
                    </div>
                    <div class="combo_footer">
                        <div class="texto_footer">Volver a Jugar</div>
                        <router-link to="/pruebate"><img src="../assets/icono_retry.svg" alt="Volver a Jugar" class="icono_footer"></router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import useJuego from '../store/contenidoJuego'

export default {
  mounted () {
    const soundPuntaje = document.querySelector('#soundPuntaje')
    soundPuntaje.play()
  },
  data () {
    const gameState = useJuego()

    return {
      puntaje: gameState.puntos.value,
      playername: '',
      soundButton: document.querySelector('#soundButton')
    }
  },
  methods: {
    submit_score: function () {
      console.log(this.puntaje, this.playername)
      fetch(
        'https://grantierra.looki.xyz/admin/ETC/',
        {
          method: 'put',
          headers: {
            Authorization: 'Basic Z3RfdXNlcjpndF9wYXNz',
            'Content-Type': 'Application/Json'
          },
          body: '{"data": [{"Nombre": "' + this.playername + '", "Puntaje": ' + this.puntaje + '}]}'
        }
      ).then(response => {
        this.$router.push('/ranking')
      })
    }
  }
}
</script>

<style scoped>
#contenedor {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/patron_ranking.svg");
    background-color: #0C1D3E;
    background-size: cover;
    background-position: center;
    padding: 18px 20px;
}
#borde {
    border-radius: 25px;
    border: 2px solid white;
    background-color: #54ceea;
    margin: 0 auto;

    max-width: 1280px;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

#header {
    width: 100%;

    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.header_icons {
    display: flex;
    align-items: center;
}
.icono {
    width: 25px;
    height: auto;
    margin: 0px 4px;
}
.icono:hover {
    cursor: pointer;
    transform: scale(1.1);
}

#supercontainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 100%;
}
#title {
    color: white;
    font: 32px "Bebas Neue"
}
#puntaje {
    background-color: #4684b2;
    color: white;
    font: 90px "Bebas Neue";
    line-height: 1;
    border-radius: 15px;
    padding: 0px 30px;
}

#main_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#logo_submit {
    border-width: 2px;
    border-style: none none solid;
    border-color: white;
    padding: 30px 0px;
    transition-duration: 200ms;
}
#logo_submit:hover {
    transform: scale(1.2);
}

#formulario {
    padding: 20px 0px;
}
#form_text {
    font: 20px "Bebas Neue";
    margin-bottom: 15px;
}

#form_input_container {
    display: flex;
    align-items: center;
    width: 90%;
    margin: 0px auto;
}
#form_text_input {
    width: 100%;
    height: 30px;
    border: none;
    border-radius: 5px 0px 0px 5px;
    font: 22px "Bebas Neue";
    text-align: center;
}
#form_button {
    background-color: #4684b2;
    height: 30px;
    padding: 3px 6px;
    display: grid;
    align-items: center;
    justify-content: center;

    border-radius: 0px 5px 5px 0px;
}
#form_button:hover {
    cursor: pointer;
}
#form_send {
    width: 25px;
}

#footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 3px;
    width: 100%
}
.combo_footer {
    display: flex;
    align-items: center;
}
.icono_footer {
    width: 40px;
    height: auto;
    margin: 0px 4px;
}
.icono_footer:hover {
    cursor: pointer;
    transform: scale(1.2);
}
.texto_footer {
    font: 18px "Bebas Neue";
    width: 50px;
}

@media screen and (min-width: 800px) {
    #title {
        color: white;
        font: 45px "Bebas Neue"
    }
    #puntaje {
        background-color: #4684b2;
        color: white;
        font: 150px "Bebas Neue";
        line-height: 1;
        border-radius: 15px;
        padding: 0px 30px;
        position: relative;
        z-index: 0;
    }

    #main_container {
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border-width: 2px;
        border-style: solid none;
        border-color: white;
        margin-top: -70px;
        margin-bottom: 80px;
    }
    #logo_submit {
        width: 40%;
        padding: 30px 0px;
        border: none;
    }

    #formulario {
        width: 40%;
        padding: 20px 0px;
    }
    #form_text {
        font: 45px "Bebas Neue";
        margin-bottom: 15px;
    }

    #form_text_input {
        width: 100%;
        height: 45px;
        border: none;
        border-radius: 10px 0px 0px 10px;
        font: 22px "Bebas Neue";
        text-align: center;
    }
    #form_button {
        background-color: #4684b2;
        height: 45px;
        padding: 3px 15px;
        display: grid;
        align-items: center;
        justify-content: center;

        border-radius: 0px 10px 10px 0px;
    }
    #form_send {
        width: 35px;
    }

    #footer {
        display: flex;
        justify-content: space-between;
        padding: 0px 0px 50px;
        width: 40%;
    }
    .combo_footer {
        display: flex;
        align-items: center;
    }
    .icono_footer {
        width: 50px;
        height: auto;
        margin: 0px 15px;
    }
    .texto_footer {
        font: 20px "Bebas Neue";
        width: 50px;
    }
}
</style>
